// Index




// CSS
import '../styles/terminator.scss';
// JS
import { gsap } from "gsap/dist/gsap";
import { TextPlugin } from "gsap/dist/TextPlugin";
import { Draggable } from "gsap/Draggable";
import { CustomEase } from "gsap/CustomEase";
import { Howl, Howler } from 'howler';
import { SplitText } from "gsap/SplitText";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// // Register GreenSock Plugins
// gsap.registerPlugin(Draggable, CustomEase);


import gubaSvg from 'bundle-text:../svg/guba.svg';


const soundURL = require("url:../audio/terminator__02.mp3");
const sound = new Howl({
    src: soundURL,
    volume: 0.5,
    autoplay: false,
    loop: true,
    preload: true,
    html5: false,
});

const welcomeURL = require("url:../audio/welcome.mp3");
const welcome = new Howl({
    src: welcomeURL,
    volume: 0.5,
    autoplay: false,
    loop: false,
    preload: true,
    html5: false,
});


let context;
window.onload = function () {
    context = new AudioContext();
}


document.addEventListener('DOMContentLoaded', function () {

    gsap.registerPlugin(CustomEase, Draggable, SplitText, TextPlugin);

    // Inject GubaThon Svg
    const gubaSpans = document.querySelectorAll('#guba__terminator > span');
    gubaSpans.forEach(function (gubaSpan) {
        gubaSpan.innerHTML = gubaSvg;
    });


    // Drag Cards 

    let itemShell = document.getElementsByClassName("limeGreen")[0];
    Draggable.create(itemShell, {
        type: "x,y",
        edgeResistance: 1.5,
        throwProps: true,
        allowEventDefault: true,
        bounds: "#guba__terminator",
        onDrag: function () {
            itemShell.className = "limeGreen window dragging";
        },
        onDragEnd: function () {
            itemShell.className = "limeGreen window";
        },
    });

    let itemShellTwo = document.getElementsByClassName("win__two")[0];
    Draggable.create(itemShellTwo, {
        type: "x,y",
        edgeResistance: 1.5,
        throwProps: true,
        allowEventDefault: true,
        bounds: "#guba__terminator",
        onDrag: function () {
            itemShellTwo.className = "win__two window dragging";
        },
        onDragEnd: function () {
            itemShellTwo.className = "win__two window";
        },
    });

    let itemShellThree = document.getElementsByClassName("win__three")[0];
    Draggable.create(itemShellThree, {
        type: "x,y",
        edgeResistance: 1.5,
        throwProps: true,
        allowEventDefault: true,
        bounds: "#guba__terminator",
        onDrag: function () {
            itemShellThree.className = "win__three window dragging";
        },
        onDragEnd: function () {
            itemShellThree.className = "win__three window";
        },
    });


    // Terminator Eye


    let cursor = document.querySelector("#cursor");
    let text = document.querySelector("#text");
    gsap.fromTo(cursor, { autoAlpha: 0, x: 0, y: -1 }, { autoAlpha: 1, duration: 0.5, repeat: -1, ease: "SteppedEase.config(1)" });

    var typeText = gsap.timeline({ paused: true, reversed: true });
    typeText.to("#text", {
        text: {
            value: "Gubathon 2023 <br>Monday, June 12<br><br>--<br><br>'Always remember, the key to victory is not in the size of your army, but in the length of your chopsticks. The longer they are, the further you can reach for your enemies' snacks' - Sun Tzu"
        },
        duration: 3,
        delay: 1.5,
        onUpdate: () => text.appendChild(cursor)
    });

    
        
    var toggleTerminator = document.querySelectorAll('.toggle__terminator');
    const mainTerminator = document.querySelectorAll('.toggleSwitch');
    const toggleSpeak = document.querySelectorAll('.voiceTerminator');
    const mainTerminatorSpeak = document.querySelectorAll('.welcomeTerminator');

    var terminatorToggle = gsap.timeline({ paused: true, reversed: true });
    var terminatorSpeak = gsap.timeline({ paused: true, reversed: true });
    // gsap.set("svg#guba__svg path", { fill: "hsla(0, 0%, 100%, 0)", stroke: "hsla(0, 0%, 100%, 0.15)" }, "=-0.5");

    terminatorToggle
        .to(".window#list", { autoAlpha:1 })
        .to("article >*:nth-of-type(2)", { duration: 0.2, background: "hsla(0, 10%, 100%, 0.15)", borderRadius: "2.5em" })
        .to(".win__one", { backgroundColor:"rgba(0,100,0,0.3)", })
        .to(".win__two .window__contents", { backgroundColor:"rgba(0,0,0,0.7)", })
        .to(".window ul li", { stagger: 0.1, duration: 0.2, autoAlpha: 1, })
        // .to("svg#guba__svg path", 0.2, { fill: "hsla(0, 100%, 50%, 1)"})
        // .to(".onhOne", 0.325, { borderColor: '#67d167' }, "=-0.1")
        .reverse()
        ;

    terminatorSpeak
        .to(toggleSpeak, { duration: 0.125, scale: 0.875,  })
        .to(toggleSpeak, { duration: 0.1, autoAlpha:0 }, "-=0.125")
        .to(".window#hacker .nextSreen", { duration: 1, autoAlpha:1 }, "-=0.25")
        // .to("svg#guba__svg path", 0.2, { fill: "hsla(0, 100%, 50%, 1)"})
        // .to(".onhOne", 0.325, { borderColor: '#67d167' }, "=-0.1")
        .reverse()
        ;
      
        
    var mute = true;

    var botStatus = document.querySelector('#botStatus');
    var html = botStatus.innerHTML;
    


    toggleTerminator.forEach(function (toggleButton) {
        if (toggleButton instanceof HTMLButtonElement) {
            toggleButton.onclick = function (event) {
                mainTerminator.forEach(look => look.classList.toggle("active"));
                terminatorToggle.reversed() ? terminatorToggle.play() : terminatorToggle.reverse();
                if (mute === true) {
                    typeText.restart()
                    sound.play();
                    sound.fade(0, 0.75, 100);
                    mute = false;
                    botStatus.innerHTML = '<a href="https://gubagoo.atlassian.net/wiki/spaces/Gubathon/pages/3555360842/Gubathon+Info+Packet">ONLINE ></a>';
                } else {
                    typeText.reverse()
                    sound.stop();
                    sound.fade(0, 0.75, 400);
                    mute = true;
                    botStatus.innerHTML = 'OFFLINE';
                }
            };


        }
    });

    toggleSpeak.forEach(function (toggleButtonSpeak) {
        if (toggleButtonSpeak instanceof HTMLButtonElement) {
            toggleButtonSpeak.onclick = function (event) {
                terminatorSpeak.reversed() ? terminatorSpeak.play() : terminatorSpeak.reverse();

                mainTerminatorSpeak.forEach(look => look.classList.toggle("active"));
                    welcome.play();
                    welcome.fade(0, 0.75, 100);
                    
            };


        }
    });


   

    const closeSesame = document.getElementById('close-button');
    let popToggle = gsap.timeline({

    });

    closeSesame.onclick = function () {

        popToggle
            .add("popToggleClick")
            .to(".limeGreen", {
                y: '-100vh',
                ease: "back.in(0.75, 1)",
                duration: 0.5,
                delay: 0.1,
                stagger: 0.1,
            }, "popToggleClick")
            .to(".limeGreen", {
                y: '100vh',
                delay: 5,
                duration: 0,
                stagger: 0.1,
            })
            .to(".limeGreen", {
                y: 0,
                ease: "back.out(0.5, 1.5)",
                duration: 0.25,
                stagger: 0.1,
            })
            ;
    };

    const closeSesameTwo = document.getElementById('close-buttonTwo');
    let popToggleTwo = gsap.timeline({

    });

    closeSesameTwo.onclick = function () {

        popToggleTwo
            .add("popToggleClick")
            .to(".win__two", {
                x: '-100vw',
                ease: "back.in(0.75, 1)",
                duration: 0.5,
                delay: 0.1,
                stagger: 0.1,
            }, "popToggleClick")
            .to(".win__two", {
                X: '100vw',
                delay: 5,
                duration: 0,
                stagger: 0.1,
            })
            .to(".win__two", {
                x: 0,
                ease: "back.out(0.5, 1.5)",
                duration: 0.25,
                stagger: 0.1,
            })
            ;
    };

    const closeSesameThree = document.getElementById('close-buttonThree');
    let popToggleThree = gsap.timeline({

    });

    closeSesameThree.onclick = function () {

        popToggleThree
            .add("popToggleClick")
            .to(".win__three", {
                x: '-100vw',
                ease: "back.in(0.75, 1)",
                duration: 0.5,
                delay: 0.1,
                stagger: 0.1,
            }, "popToggleClick")
            .to(".win__three", {
                X: '100vw',
                delay: 5,
                duration: 0,
                stagger: 0.1,
            })
            .to(".win__three", {
                x: 0,
                ease: "back.out(0.5, 1.5)",
                duration: 0.25,
                stagger: 0.1,
            })
            ;
    };

}, false);
